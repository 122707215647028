import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-58148ba8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_icon = _resolveComponent("van-icon");

  return _openBlock(), _createElementBlock(_Fragment, null, [_renderSlot(_ctx.$slots, "content", {}, undefined, true), _ctx.content ? (_openBlock(), _createElementBlock("p", {
    key: 0,
    class: _normalizeClass(["info-content wrap-wording", {
      active: _ctx.isMore
    }]),
    ref: "content"
  }, _toDisplayString(_ctx.content), 3)) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", {
    class: "read-more",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.toggleContent();
    })
  }, [_createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.more), 1), _createVNode(_component_van_icon, {
    name: _ctx.icon
  }, null, 8, ["name"])], 512), [[_vShow, _ctx.toggleReadMore]])], 64);
}