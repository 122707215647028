import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6041a103"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "ct-popup"
};
var _hoisted_2 = {
  class: "button-group"
};
var _hoisted_3 = {
  class: "button-group"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_contry_travel_description = _resolveComponent("contry-travel-description");

  var _component_dialog_component = _resolveComponent("dialog-component");

  var _component_page = _resolveComponent("page");

  var _component_popup = _resolveComponent("popup");

  return _openBlock(), _createElementBlock("section", _hoisted_1, [!_ctx.isMobile ? (_openBlock(), _createBlock(_component_dialog_component, {
    key: 0,
    open: _ctx.isDescriptionOpen,
    title: _ctx.t('hotel.national_travel_subsidy_directions'),
    width: 600,
    onClose: function onClose() {
      return _ctx.$emit('onCloseDescription');
    }
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_contry_travel_description), _createElementVNode("section", _hoisted_2, [_createElementVNode("button", {
        class: "btn",
        onClick: _cache[0] || (_cache[0] = function () {
          return _ctx.$emit('onCloseDescription');
        })
      }, _toDisplayString(_ctx.t('order.national_travel.understand')), 1)])];
    }),
    _: 1
  }, 8, ["open", "title", "onClose"])) : (_openBlock(), _createBlock(_component_popup, {
    key: 1,
    show: _ctx.isDescriptionOpen
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_page, {
        onClose: function onClose() {
          return _ctx.$emit('onCloseDescription');
        },
        title: _ctx.t('hotel.national_travel_subsidy_directions')
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_contry_travel_description), _createElementVNode("section", _hoisted_3, [_createElementVNode("button", {
            class: "btn",
            onClick: _cache[1] || (_cache[1] = function () {
              return _ctx.$emit('onCloseDescription');
            })
          }, _toDisplayString(_ctx.t('order.national_travel.understand')), 1)])];
        }),
        _: 1
      }, 8, ["onClose", "title"])];
    }),
    _: 1
  }, 8, ["show"]))]);
}