import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-39e7626b"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_nav_bar = _resolveComponent("van-nav-bar");

  var _component_popup = _resolveComponent("popup");

  var _component_van_config_provider = _resolveComponent("van-config-provider");

  return _openBlock(), _createBlock(_component_van_config_provider, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_popup, {
        class: "comment-popup",
        show: _ctx.open,
        round: "",
        style: {
          overflow: 'revert'
        }
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_van_nav_bar, {
            title: _ctx.title,
            class: "mobile-only"
          }, {
            right: _withCtx(function () {
              return [_createVNode(_component_svg_icon, {
                class: "menu-icon",
                iconName: "nav-close",
                onClick: _cache[0] || (_cache[0] = function ($event) {
                  return _ctx.handleOpen();
                })
              })];
            }),
            _: 1
          }, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), _createVNode(_component_svg_icon, {
            class: "close-icon pc-only",
            iconName: "icon_48_close_big",
            onClick: _cache[1] || (_cache[1] = function () {
              return _ctx.handleOpen();
            })
          })];
        }),
        _: 3
      }, 8, ["show"])];
    }),
    _: 3
  });
}