import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/nav-bar/style";
import _NavBar from "vant/es/nav-bar";

var _components;

import "core-js/modules/es.function.name.js";
import Popup from '@/components/share/Popup.vue';
import GallerySlideShow from '@/components/share/GallerySlideShow.vue';
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'HotelPhotos',
  components: (_components = {
    Popup: Popup
  }, _defineProperty(_components, _NavBar.name, _NavBar), _defineProperty(_components, "GallerySlideShow", GallerySlideShow), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: {
    rooms: Array
  },
  setup: function setup(props) {
    var t = inject('t');
    var store = useStore();
    var showRoom = computed(function () {
      return store.state.hotel.popupShowRoom.isShow;
    });
    var showRoomIndex = computed(function () {
      return store.state.hotel.popupShowRoom.showOn;
    });

    var toggleRoomDetail = function toggleRoomDetail() {
      store.dispatch('hotel/toggleRoomPopup', showRoomIndex);
    };

    var roomData = computed(function () {
      return props.rooms && props.rooms[showRoomIndex.value];
    });
    var popupStyle = ref({
      width: '100vw',
      height: 'auto',
      overflow: 'unset'
    });
    var isMobile = inject('isMobile');

    var changeStyle = function changeStyle(mobile) {
      if (mobile.value) {
        popupStyle.value = {
          width: '100vw',
          height: 'auto',
          overflow: 'unset'
        };
      } else {
        popupStyle.value = {
          width: 'calc(100vw - 60px)',
          height: 'calc(100vh - 80px)',
          overflow: 'revert'
        };
      }
    };

    onMounted(function () {
      changeStyle(isMobile);
    });
    watch(isMobile, function () {
      return changeStyle(isMobile);
    });
    return {
      popupStyle: popupStyle,
      roomData: roomData,
      toggleRoomDetail: toggleRoomDetail,
      showRoomIndex: showRoomIndex,
      showRoom: showRoom,
      t: t
    };
  }
});