import "core-js/modules/es.array.slice.js";
import { defineComponent, inject } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'HotelPhotos',
  props: {
    hotel: {
      type: Object
    }
  },
  setup: function setup(props) {
    var store = useStore();

    var togglePhotos = function togglePhotos(index) {
      store.dispatch('hotel/toggleHotelPopup', index);
    };

    var mobileImage = function mobileImage() {
      var _props$hotel, _props$hotel$small_im;

      return ((_props$hotel = props.hotel) === null || _props$hotel === void 0 ? void 0 : (_props$hotel$small_im = _props$hotel.small_images) === null || _props$hotel$small_im === void 0 ? void 0 : _props$hotel$small_im.slice(0, 5)) || [];
    };

    var desktopImage = function desktopImage() {
      var _props$hotel2, _props$hotel2$images;

      return ((_props$hotel2 = props.hotel) === null || _props$hotel2 === void 0 ? void 0 : (_props$hotel2$images = _props$hotel2.images) === null || _props$hotel2$images === void 0 ? void 0 : _props$hotel2$images.slice(0, 5)) || [];
    };

    var mobileLength = function mobileLength() {
      var _mobileImage;

      return (_mobileImage = mobileImage()) === null || _mobileImage === void 0 ? void 0 : _mobileImage.length;
    };

    var desktopLength = function desktopLength() {
      var _desktopImage;

      return (_desktopImage = desktopImage()) === null || _desktopImage === void 0 ? void 0 : _desktopImage.length;
    };

    var desktopImagesStyle = function desktopImagesStyle() {
      switch (desktopLength()) {
        case 2:
          return 'images images2 empty';

        case 3:
          return 'images empty';
      }
    };

    var mobileImagesStyle = function mobileImagesStyle() {
      switch (mobileLength()) {
        case 2:
          return 'images empty m-images3';

        case 3:
          return 'images empty m-images2';

        case 4:
          return 'images empty m-images1';
      }
    };

    var mainImagesStyle = function mainImagesStyle(length) {
      switch (length) {
        case 1:
          return 'images extend';

        default:
          return 'images';
      }
    };

    var isMobile = inject('isMobile');
    return {
      togglePhotos: togglePhotos,
      mobileImage: mobileImage,
      desktopImage: desktopImage,
      mobileLength: mobileLength,
      desktopLength: desktopLength,
      desktopImagesStyle: desktopImagesStyle,
      mainImagesStyle: mainImagesStyle,
      mobileImagesStyle: mobileImagesStyle,
      isMobile: isMobile
    };
  }
});