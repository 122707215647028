import { defineComponent } from 'vue';
import Header from '@/components/Header.vue'; // @ is an alias to /src

import MobileSearch from '@/components/mobile/MobileSearch.vue';
import PcSearch from '@/components/hotels/PcSearch.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import HotelsMain from '@/components/hotel/Main.vue';
import Footer from '@/components/Footer.vue';
import ContactBtn from '@/components/hotel/ContactBtn.vue';
export default defineComponent({
  name: 'Hotels',
  data: function data() {
    return {};
  },
  inject: ['isMobile'],
  components: {
    HeaderComponent: Header,
    PcSearch: PcSearch,
    MobileSearch: MobileSearch,
    Breadcrumb: Breadcrumb,
    HotelsMain: HotelsMain,
    FooterComponent: Footer,
    ContactBtn: ContactBtn
  },
  mounted: function mounted() {
    setTimeout(function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100);
  }
});