import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "core-js/modules/es.function.name.js";
import { computed, defineComponent, inject, ref } from 'vue';
export default defineComponent({
  name: 'Readmore',
  components: _defineProperty({}, _Icon.name, _Icon),
  props: {
    content: String,
    type: String,
    target: String
  },
  data: function data() {
    return {
      toggleReadMore: false
    };
  },
  methods: {
    toggle: function toggle() {
      if (this.$props.type === 'slot') {
        var slot = document.querySelector("#".concat(this.$props.target));

        if (slot && slot.clientHeight >= 90) {
          this.toggleReadMore = true;
        }
      } else {
        var content = this.$refs.content;

        if (content && content.clientHeight >= 120) {
          this.toggleReadMore = true;
        }
      }
    }
  },
  mounted: function mounted() {
    this.toggle();
  },
  updated: function updated() {
    var _this = this;

    this.$nextTick(function () {
      if (_this.$props.type || _this.$props.content) {
        _this.toggle();
      }
    });
  },
  setup: function setup(props) {
    var t = inject('t');
    var isMore = ref(false);

    var toggleContent = function toggleContent() {
      if (props.type === 'slot') {
        var slot = document.getElementById(props.target || '');

        if (!isMore.value === true) {
          slot.classList.add('active');
        } else {
          slot.classList.remove('active');
        }
      }

      isMore.value = !isMore.value;
    };

    var more = computed(function () {
      return isMore.value ? t('detail.show_less') : t('detail.show_more');
    });
    var icon = computed(function () {
      return isMore.value ? 'arrow-up' : 'arrow-down';
    });
    return {
      isMore: isMore,
      toggleContent: toggleContent,
      more: more,
      icon: icon
    };
  }
});