import { defineComponent, inject } from 'vue';
import ContryTravelDescription from './ContryTravelDescription.vue';
import Dialog from '@/components/share/Dialog.vue';
export default defineComponent({
  name: 'HotelsInfo',
  components: {
    DialogComponent: Dialog,
    ContryTravelDescription: ContryTravelDescription
  },
  emit: ['onCloseDescription', 'onCloseDescription'],
  props: ['isDescriptionOpen'],
  setup: function setup() {
    var t = inject('t');
    var isMobile = inject('isMobile');
    return {
      t: t,
      isMobile: isMobile
    };
  }
});