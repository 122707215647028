import _slicedToArray from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";
import "vant/es/progress/style";
import _Progress from "vant/es/progress";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import { computed, defineComponent, inject, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Readmore from '@/components/share/Readmore.vue';
export default defineComponent({
  name: 'Comment',
  components: (_components = {}, _defineProperty(_components, _Progress.name, _Progress), _defineProperty(_components, _Popover.name, _Popover), _defineProperty(_components, _Image.name, _Image), _defineProperty(_components, _List.name, _List), _defineProperty(_components, "Readmore", Readmore), _components),
  props: {
    hotel: {
      type: Object
    },
    isPopup: Boolean
  },
  setup: function setup(props) {
    var t = inject('t');
    var store = useStore();
    var reviewList = computed(function () {
      return store.state.hotel.reviewList;
    });
    var hotelRanking = computed(function () {
      return store.state.hotel.hotelRanking;
    });
    var reviewPage = computed(function () {
      return store.state.hotel.reviewPagination;
    });
    var getReviewList = computed(function () {
      var _reviewList$value;

      return props.isPopup ? reviewList.value : (_reviewList$value = reviewList.value) === null || _reviewList$value === void 0 ? void 0 : _reviewList$value.slice(0, 5);
    });
    var currentPage = computed(function () {
      return reviewPage.value.current_page || 0;
    });
    var reviewLoading = computed(function () {
      return store.state.hotel.reviewLoading;
    });
    var commentChannel = ref('Google');
    var commentOrder = ref({
      key: 'newest',
      lang_key: 'detail.latest_comments'
    });

    var fetchReview = function fetchReview(page) {
      var _props$hotel;

      store.dispatch('hotel/fetchReviews', {
        hotel_id: (_props$hotel = props.hotel) === null || _props$hotel === void 0 ? void 0 : _props$hotel.hotel_id,
        sorting: commentOrder.value.key,
        source: commentChannel.value.toLowerCase(),
        page: page
      });
    };

    watch(function () {
      var _props$hotel2;

      return (_props$hotel2 = props.hotel) === null || _props$hotel2 === void 0 ? void 0 : _props$hotel2.hotel_id;
    }, function () {
      fetchReview(1);
    });
    var locale = inject('locale');
    watch(locale, function () {
      fetchReview(1);
    });
    var isMobile = inject('isMobile');
    var reviewDraw = ref(['5', '4', '3', '2', '1']);
    var channelShowPopover = ref(false);

    var onSelectChannel = function onSelectChannel(text) {
      commentChannel.value = text;
      fetchReview(1);
      channelShowPopover.value = false;
    };

    var commentChannels = ref(['Google', 'TripAdvisor', 'tourbobo']);
    var orderShowPopover = ref(false);

    var onSelectOrder = function onSelectOrder(sort) {
      commentOrder.value = sort;
      fetchReview(1);
      orderShowPopover.value = false;
    };

    var commentOrders = ref([{
      key: 'newest',
      lang_key: 'detail.latest_comments'
    }, {
      key: 'highest_ranking',
      lang_key: 'detail.highest_ranking'
    }, {
      key: 'lowest_ranking',
      lang_key: 'detail.lowest_ranking'
    }]);
    var rates = ['one_rate', 'two_rate', 'three_rate', 'four_rate', 'five_rate'];
    var reviewRankings = computed(function () {
      return rates.map(function (rate) {
        return hotelRanking.value[rate];
      });
    });

    var getDate = function getDate(date) {
      var _date$split = date.split(' '),
          _date$split2 = _slicedToArray(_date$split, 1),
          day = _date$split2[0];

      return day;
    };

    var isFinished = computed(function () {
      return reviewPage.value.current_page === reviewPage.value.total_pages;
    });

    var onLoad = function onLoad() {
      if (props.isPopup) {
        fetchReview(currentPage.value + 1);
      }
    };

    return {
      t: t,
      isMobile: isMobile,
      reviewDraw: reviewDraw,
      reviewRankings: reviewRankings,
      hotelRanking: hotelRanking,
      reviewList: reviewList,
      reviewLoading: reviewLoading,
      isFinished: isFinished,
      getDate: getDate,
      onLoad: onLoad,
      channelShowPopover: channelShowPopover,
      commentChannel: commentChannel,
      onSelectChannel: onSelectChannel,
      commentChannels: commentChannels,
      orderShowPopover: orderShowPopover,
      commentOrder: commentOrder,
      onSelectOrder: onSelectOrder,
      commentOrders: commentOrders,
      getReviewList: getReviewList
    };
  }
});