import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5b3379bf"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_header_component = _resolveComponent("header-component");

  var _component_pc_search = _resolveComponent("pc-search");

  var _component_mobile_search = _resolveComponent("mobile-search");

  var _component_breadcrumb = _resolveComponent("breadcrumb");

  var _component_hotels_main = _resolveComponent("hotels-main");

  var _component_footer_component = _resolveComponent("footer-component");

  var _component_contact_btn = _resolveComponent("contact-btn");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_header_component), _createElementVNode("main", null, [!_ctx.isMobile ? (_openBlock(), _createBlock(_component_pc_search, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_mobile_search, {
    key: 1
  })), _createElementVNode("div", _hoisted_1, [_createVNode(_component_breadcrumb, {
    class: "pc-only"
  }), _createVNode(_component_hotels_main)])]), _createVNode(_component_footer_component), _createVNode(_component_contact_btn)], 64);
}