import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-05400ccc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "photos"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("section", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.isMobile ? _ctx.mobileImage() : _ctx.desktopImage(), function (image, index) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(_ctx.isMobile ? _ctx.mainImagesStyle(_ctx.mobileLength()) : _ctx.mainImagesStyle(_ctx.desktopLength())),
      style: _normalizeStyle({
        'background-image': 'url(' + image + ')'
      }),
      key: index,
      onClick: function onClick($event) {
        return _ctx.togglePhotos(index);
      }
    }, null, 14, _hoisted_2);
  }), 128)), _ctx.mobileLength() < 5 && _ctx.mobileLength() > 2 ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.mobileImagesStyle()),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.togglePhotos(0);
    })
  }, null, 2)) : _createCommentVNode("", true), _ctx.desktopLength() === 3 || _ctx.desktopLength() === 2 ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(_ctx.desktopImagesStyle()),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.togglePhotos(0);
    })
  }, null, 2)) : _createCommentVNode("", true)]);
}