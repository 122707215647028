import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-dac7ab06"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "info"
};
var _hoisted_2 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_readmore = _resolveComponent("readmore");

  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t('detail.about_the_hotel')), 1), _createVNode(_component_readmore, {
    content: _ctx.contentFormat(_ctx.hotel.intro)
  }, null, 8, ["content"])]);
}