import { defineComponent, inject } from 'vue';
import Readmore from '@/components/share/Readmore.vue';
import { contentFormat } from '@/utils/contentFormat';
export default defineComponent({
  name: 'HotelsInfo',
  components: {
    Readmore: Readmore
  },
  props: {
    hotel: {
      type: Object
    }
  },
  setup: function setup() {
    var t = inject('t');
    return {
      t: t,
      contentFormat: contentFormat
    };
  }
});