import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/nav-bar/style";
import _NavBar from "vant/es/nav-bar";

var _components;

import "core-js/modules/es.function.name.js";
import Popup from '@/components/share/Popup.vue';
import GallerySlideShow from '@/components/share/GallerySlideShow.vue';
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'HotelPhotos',
  components: (_components = {
    Popup: Popup
  }, _defineProperty(_components, _NavBar.name, _NavBar), _defineProperty(_components, "GallerySlideShow", GallerySlideShow), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: {
    hotel: {
      type: Object
    },
    isShow: {
      type: Boolean
    }
  },
  methods: {
    swipeTo: function swipeTo(num) {
      var swipeRef = this.$refs.swipe;
      swipeRef && swipeRef.swipeTo(num);
    }
  },
  watch: {
    isShow: function isShow(newVal) {
      var _this = this;

      if (newVal) {
        this.$nextTick(function () {
          _this.swipeTo(_this.showPhotoIndex);
        });
      }
    }
  },
  setup: function setup() {
    var store = useStore();
    var t = inject('t');
    var showPhotoIndex = computed(function () {
      return store.state.hotel.popupShowHotel.showOn;
    });

    var togglePhotos = function togglePhotos() {
      store.dispatch('hotel/toggleHotelPopup', 0);
    };

    var popupStyle = ref({
      width: '100vw',
      height: 'auto',
      overflow: 'unset'
    });
    var isMobile = inject('isMobile');

    var changeStyle = function changeStyle(mobile) {
      if (mobile.value) {
        popupStyle.value = {
          width: '100vw',
          height: 'auto',
          overflow: 'unset'
        };
      } else {
        popupStyle.value = {
          width: 'calc(100vw - 60px)',
          height: 'calc(100vh - 80px)',
          overflow: 'revert'
        };
      }
    };

    onMounted(function () {
      changeStyle(isMobile);
    });
    watch(isMobile, function () {
      return changeStyle(isMobile);
    });
    return {
      t: t,
      togglePhotos: togglePhotos,
      popupStyle: popupStyle,
      showPhotoIndex: showPhotoIndex
    };
  }
});