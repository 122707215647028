import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/nav-bar/style";
import _NavBar from "vant/es/nav-bar";
import "vant/es/config-provider/style";
import _ConfigProvider from "vant/es/config-provider";

var _components;

import "core-js/modules/es.function.name.js";
import { defineComponent } from 'vue';
import Popup from '@/components/share/Popup.vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'HotelsMap',
  components: (_components = {}, _defineProperty(_components, _ConfigProvider.name, _ConfigProvider), _defineProperty(_components, _NavBar.name, _NavBar), _defineProperty(_components, "Popup", Popup), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: ['open', 'handleOpen', 'title']
});