import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.sort.js";
import { computed, defineComponent, inject } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'HotelHighlight',
  components: (_components = {}, _defineProperty(_components, _Icon.name, _Icon), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: {
    hotel: {
      type: Object
    }
  },
  setup: function setup(props) {
    var t = inject('t');
    var highlights = computed(function () {
      var _props$hotel, _props$hotel$features, _props$hotel$features2, _props$hotel2, _props$hotel2$feature, _props$hotel2$feature2, _props$hotel3, _props$hotel3$feature, _props$hotel3$feature2;

      return [{
        type: 'detail.entertaining_facilities',
        text: ((_props$hotel = props.hotel) === null || _props$hotel === void 0 ? void 0 : (_props$hotel$features = _props$hotel.features) === null || _props$hotel$features === void 0 ? void 0 : (_props$hotel$features2 = _props$hotel$features.entertainment) === null || _props$hotel$features2 === void 0 ? void 0 : _props$hotel$features2.lang_key) || '',
        icon: 'hotel-detail-game'
      }, {
        type: 'detail.room_facilities',
        text: ((_props$hotel2 = props.hotel) === null || _props$hotel2 === void 0 ? void 0 : (_props$hotel2$feature = _props$hotel2.features) === null || _props$hotel2$feature === void 0 ? void 0 : (_props$hotel2$feature2 = _props$hotel2$feature.room) === null || _props$hotel2$feature2 === void 0 ? void 0 : _props$hotel2$feature2.lang_key) || '',
        icon: 'hotel-detail-facilities'
      }, {
        type: 'detail.hotel_services',
        text: ((_props$hotel3 = props.hotel) === null || _props$hotel3 === void 0 ? void 0 : (_props$hotel3$feature = _props$hotel3.features) === null || _props$hotel3$feature === void 0 ? void 0 : (_props$hotel3$feature2 = _props$hotel3$feature.hotel) === null || _props$hotel3$feature2 === void 0 ? void 0 : _props$hotel3$feature2.lang_key) || '',
        icon: 'hotel-detail-service'
      }].sort(function (a, b) {
        if (a.text && b.text === '') {
          return -1;
        }

        if (a.text === '' && b.text) {
          return 1;
        }

        return 0;
      });
    });
    return {
      t: t,
      highlights: highlights
    };
  }
});