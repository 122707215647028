import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-03fcfc0c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "info"
};
var _hoisted_2 = {
  class: "container"
};
var _hoisted_3 = {
  class: "row"
};
var _hoisted_4 = {
  class: "sub-title"
};
var _hoisted_5 = {
  class: "time-wrap"
};
var _hoisted_6 = {
  class: "time"
};
var _hoisted_7 = {
  class: "row"
};
var _hoisted_8 = {
  class: "sub-title"
};
var _hoisted_9 = {
  class: "time-wrap"
};
var _hoisted_10 = {
  class: "time"
};
var _hoisted_11 = {
  class: "sub-title"
};
var _hoisted_12 = {
  class: "rule"
};
var _hoisted_13 = {
  class: "table"
};
var _hoisted_14 = {
  class: "thead"
};
var _hoisted_15 = ["colspan"];
var _hoisted_16 = {
  class: "th th-width-rule"
};
var _hoisted_17 = {
  key: 0,
  class: "tbody"
};
var _hoisted_18 = {
  key: 0
};
var _hoisted_19 = {
  class: "td",
  width: "20%"
};
var _hoisted_20 = {
  class: "center"
};
var _hoisted_21 = {
  class: "td"
};
var _hoisted_22 = {
  key: 1
};
var _hoisted_23 = {
  class: "td",
  width: "20%"
};
var _hoisted_24 = {
  class: "center"
};
var _hoisted_25 = {
  class: "td"
};
var _hoisted_26 = {
  key: 2
};
var _hoisted_27 = {
  class: "td",
  width: "20%"
};
var _hoisted_28 = {
  class: "center"
};
var _hoisted_29 = {
  class: "td"
};
var _hoisted_30 = {
  key: 0
};
var _hoisted_31 = {
  class: "sub-title"
};
var _hoisted_32 = {
  key: 0,
  class: "info"
};
var _hoisted_33 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_readmore = _resolveComponent("readmore");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('detail.check_in_time')), 1), _createElementVNode("div", _hoisted_5, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "clock"
  }), _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.hotel.check_in), 1)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('detail.check_out_time')), 1), _createElementVNode("div", _hoisted_9, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "clock"
  }), _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.hotel.check_out), 1)])])]), _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.t('detail.extra_capacity_rule')), 1), _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.t('detail.the_extra_charges_on_extra_minors_and_adults_are')), 1), _createElementVNode("table", _hoisted_13, [_createElementVNode("thead", _hoisted_14, [_createElementVNode("tr", null, [_createElementVNode("th", {
    class: "th th-width-age",
    colspan: _ctx.isMobile ? 1 : 2
  }, _toDisplayString(_ctx.t('detail.age')), 9, _hoisted_15), _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.t('detail.extra_capacity_rule')), 1)])]), _ctx.hotel.ages ? (_openBlock(), _createElementBlock("tbody", _hoisted_17, [_ctx.hotel.ages.accept_baby ? (_openBlock(), _createElementBlock("tr", _hoisted_18, [_createElementVNode("td", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "baby"
  }), _createElementVNode("span", null, _toDisplayString(_ctx.t('detail.n_years_old_and_minus', {
    n: _ctx.hotel.ages.accept_baby_under
  })), 1)])]), _withDirectives(_createElementVNode("td", {
    class: "td"
  }, _toDisplayString(_ctx.t('detail.infants')), 513), [[_vShow, !_ctx.isMobile]]), _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.getExtraWording(_ctx.hotel.ages.baby_extra_charge)), 1)])) : _createCommentVNode("", true), _ctx.hotel.ages.accept_child ? (_openBlock(), _createElementBlock("tr", _hoisted_22, [_createElementVNode("td", _hoisted_23, [_createElementVNode("div", _hoisted_24, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "child"
  }), _createElementVNode("span", null, _toDisplayString(_ctx.t('detail.m_n_years_old', {
    m: _ctx.hotel.ages.accept_baby_under,
    n: _ctx.hotel.ages.accept_child_under
  })), 1)])]), _withDirectives(_createElementVNode("td", {
    class: "td"
  }, _toDisplayString(_ctx.t('detail.children')), 513), [[_vShow, !_ctx.isMobile]]), _createElementVNode("td", _hoisted_25, _toDisplayString(_ctx.getExtraWording(_ctx.hotel.ages.child_extra_charge)), 1)])) : _createCommentVNode("", true), _ctx.hotel.ages.accept_adult ? (_openBlock(), _createElementBlock("tr", _hoisted_26, [_createElementVNode("td", _hoisted_27, [_createElementVNode("div", _hoisted_28, [_createVNode(_component_svg_icon, {
    class: "svg-icon",
    iconName: "adult"
  }), _createElementVNode("span", null, _toDisplayString(_ctx.t('detail.n_years_old_and_plus', {
    n: _ctx.hotel.ages.accept_adult_under
  })), 1)])]), _withDirectives(_createElementVNode("td", {
    class: "td"
  }, _toDisplayString(_ctx.t('detail.adults')), 513), [[_vShow, !_ctx.isMobile]]), _createElementVNode("td", _hoisted_29, _toDisplayString(_ctx.getExtraWording(_ctx.hotel.ages.adult_extra_charge)), 1)])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]), _ctx.hotel.notice ? (_openBlock(), _createElementBlock("div", _hoisted_30, [_createElementVNode("h3", _hoisted_31, _toDisplayString(_ctx.t('detail.room_notices')), 1), _createVNode(_component_readmore, {
    content: _ctx.contentFormat(_ctx.hotel.notice)
  }, null, 8, ["content"])])) : _createCommentVNode("", true)]), _ctx.hotel.booking_notice ? (_openBlock(), _createElementBlock("section", _hoisted_32, [_createElementVNode("h2", _hoisted_33, _toDisplayString(_ctx.t('detail.hotel_notices')), 1), _createVNode(_component_readmore, {
    content: _ctx.contentFormat(_ctx.hotel.booking_notice)
  }, null, 8, ["content"])])) : _createCommentVNode("", true)], 64);
}