import { defineComponent, inject } from 'vue';
import Readmore from '@/components/share/Readmore.vue';
import RulesOfAccommodation from '@/components/hotel/RulesOfAccommodation.vue';
import { contentFormat } from '@/utils/contentFormat';
export default defineComponent({
  name: 'Notice',
  components: {
    Readmore: Readmore,
    RulesOfAccommodation: RulesOfAccommodation
  },
  props: {
    hotel: {
      type: Object
    }
  },
  setup: function setup() {
    var t = inject('t');
    var isMobile = inject('isMobile');

    var getExtraWording = function getExtraWording(count) {
      return count === 0 ? t('detail.please_contact_the_customer_service_for_the_extra_person_rules_and_room_price_if_needed') : t('detail.the_room_price_per_night_with_no_bed_added_is_nt_count_per_head', {
        count: count
      });
    };

    return {
      t: t,
      isMobile: isMobile,
      getExtraWording: getExtraWording,
      contentFormat: contentFormat
    };
  }
});