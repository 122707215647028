import _toConsumableArray from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { computed, defineComponent, inject, reactive } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
import PopupRoomDetails from '@/components/hotel/PopupRoomDetails.vue';
import TooltipIcon from '../share/TooltipIcon.vue';
import { useAuthStore } from '@/hook/useAuthStore';
export default defineComponent({
  name: 'RoomTypes',
  components: {
    SvgIcon: SvgIcon,
    PopupRoomDetails: PopupRoomDetails,
    TooltipIcon: TooltipIcon
  },
  props: {
    hotel: {
      type: Object
    }
  },
  setup: function setup(props) {
    var store = useStore(); // for tags tab

    var isMobile = inject('isMobile');
    var t = inject('t');
    var locale = inject('locale');
    var isLoggedIn = useAuthStore().isLoggedIn;
    var isCancelable = computed(function () {
      var _props$hotel, _props$hotel$cancella;

      return (_props$hotel = props.hotel) === null || _props$hotel === void 0 ? void 0 : (_props$hotel$cancella = _props$hotel.cancellation_policy) === null || _props$hotel$cancella === void 0 ? void 0 : _props$hotel$cancella[0].free;
    });
    var tags = computed(function () {
      var _props$hotel2, _props$hotel2$room_ty;

      var allTags = (_props$hotel2 = props.hotel) === null || _props$hotel2 === void 0 ? void 0 : (_props$hotel2$room_ty = _props$hotel2.room_types) === null || _props$hotel2$room_ty === void 0 ? void 0 : _props$hotel2$room_ty.flatMap(function (room) {
        return room.features;
      });
      return (allTags || []).reduce(function (acc, cur) {
        if (!acc.find(function (item) {
          return item.lang_key === cur.lang_key;
        })) {
          return [].concat(_toConsumableArray(acc), [cur]);
        }

        return acc;
      }, []);
    });
    var selected = reactive(new Set());

    var setSelected = function setSelected(index) {
      if (selected.has(index)) {
        selected.delete(index);
      } else {
        selected.add(index);
      }
    };

    var getSelected = function getSelected(index) {
      return selected.has(index);
    };

    var clearSelected = function clearSelected() {
      selected.clear();
    };

    var roomList = computed(function () {
      var _props$hotel3, _props$hotel3$room_ty;

      return (_props$hotel3 = props.hotel) === null || _props$hotel3 === void 0 ? void 0 : (_props$hotel3$room_ty = _props$hotel3.room_types) === null || _props$hotel3$room_ty === void 0 ? void 0 : _props$hotel3$room_ty.filter(function (room) {
        var _room$features, _tags$value;

        var facilities = (_room$features = room.features) === null || _room$features === void 0 ? void 0 : _room$features.map(function (i) {
          return i.lang_key || '';
        });
        var tagList = (_tags$value = tags.value) === null || _tags$value === void 0 ? void 0 : _tags$value.filter(function (_, index) {
          return selected.has(index);
        }).map(function (i) {
          return (i === null || i === void 0 ? void 0 : i.lang_key) || '';
        });
        var filterArr = tagList.filter(function (item) {
          return facilities.includes(item);
        });
        return filterArr.length === tagList.length || tagList.length === 0;
      });
    });

    var extraPerson = function extraPerson() {
      return 0;
    };

    var extraBed = function extraBed(extra) {
      return extra ? 'detail.extra_beds' : 'detail.no_extra_beds';
    };

    var searchInfo = computed(function () {
      return store.getters['form/getSearchInfo'];
    });

    var getLink = function getLink(hotelId, planId, roomTypeId) {
      return "/".concat(locale.value, "/order-form?hotel_id=").concat(hotelId, "&plan_id=").concat(planId, "&room_type_id=").concat(roomTypeId, "&").concat(searchInfo.value);
    };

    var toggleRoomDetail = function toggleRoomDetail(index) {
      store.dispatch('hotel/toggleRoomPopup', index);
    };

    var searchAgain = function searchAgain() {
      store.dispatch('form/setSearchBar', 'location');
      store.dispatch('form/setIsExpand', true);
    };

    var tips = 'homepage.the_prevention_rules_accept_only_one_person_in_one_room_any_person_in_need_of_extra_person_in_one_room_must_receive_the_permit_of_the_local_government_s_health_competent_authority';
    return {
      t: t,
      isMobile: isMobile,
      isLoggedIn: isLoggedIn,
      tags: tags,
      getSelected: getSelected,
      searchInfo: searchInfo,
      getLink: getLink,
      setSelected: setSelected,
      clearSelected: clearSelected,
      roomList: roomList,
      toggleRoomDetail: toggleRoomDetail,
      searchAgain: searchAgain,
      extraBed: extraBed,
      extraPerson: extraPerson,
      tips: tips,
      isCancelable: isCancelable
    };
  }
});