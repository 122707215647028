import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2db22f9f"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "sider"
};
var _hoisted_2 = {
  class: "popup-container"
};
var _hoisted_3 = {
  class: "pc-swipe pc-only"
};
var _hoisted_4 = {
  class: "custom-indicator"
};
var _hoisted_5 = {
  class: "info none-border"
};
var _hoisted_6 = {
  class: "hotel-title"
};
var _hoisted_7 = {
  class: "sub-title"
};
var _hoisted_8 = {
  class: "container service no-bottom"
};
var _hoisted_9 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_nav_bar = _resolveComponent("van-nav-bar");

  var _component_gallery_slide_show = _resolveComponent("gallery-slide-show");

  var _component_van_swipe_item = _resolveComponent("van-swipe-item");

  var _component_van_swipe = _resolveComponent("van-swipe");

  var _component_popup = _resolveComponent("popup");

  return _openBlock(), _createBlock(_component_popup, {
    show: _ctx.isShow,
    clickOverlay: _ctx.togglePhotos,
    style: _normalizeStyle(_ctx.popupStyle)
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_svg_icon, {
        class: "pc-menu-icon pc-only",
        iconName: "icon_48_close_big",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.togglePhotos();
        })
      }), _createElementVNode("aside", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        title: _ctx.t('detail.info_on_the_hotel'),
        class: "mobile-only"
      }, {
        right: _withCtx(function () {
          return [_createVNode(_component_svg_icon, {
            class: "menu-icon",
            iconName: "nav-close",
            onClick: _cache[1] || (_cache[1] = function ($event) {
              return _ctx.togglePhotos();
            })
          })];
        }),
        _: 1
      }, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_gallery_slide_show, {
        images: _ctx.hotel.images,
        index: _ctx.showPhotoIndex,
        onClose: _cache[2] || (_cache[2] = function ($event) {
          return _ctx.index = null;
        })
      }, null, 8, ["images", "index"])]), _createVNode(_component_van_swipe, {
        ref: "swipe",
        class: "my-swipe mobile-only",
        "indicator-color": "white"
      }, {
        indicator: _withCtx(function (_ref) {
          var active = _ref.active;
          return [_createElementVNode("div", _hoisted_4, _toDisplayString(active + 1) + "/" + _toDisplayString(_ctx.hotel.images.length), 1)];
        }),
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotel.images, function (image, index) {
            return _openBlock(), _createBlock(_component_van_swipe_item, {
              key: index
            }, {
              default: _withCtx(function () {
                return [_createElementVNode("div", {
                  class: "img",
                  style: _normalizeStyle({
                    'background-image': 'url(' + image + ')'
                  })
                }, null, 4)];
              }),
              _: 2
            }, 1024);
          }), 128))];
        }),
        _: 1
      }, 512), _createElementVNode("section", _hoisted_5, [_createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.hotel.name), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotel.facilities, function (service, index) {
        return _openBlock(), _createElementBlock("div", {
          class: "service-wrapper",
          key: index
        }, [_createElementVNode("h3", _hoisted_7, _toDisplayString(service.name), 1), _createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(service.facilities, function (data, index) {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: index
          }, [_createElementVNode("div", {
            class: _normalizeClass('myicon ' + data.class)
          }, null, 2), _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t(data.lang_key)), 1)]);
        }), 128))])]);
      }), 128))])])])];
    }),
    _: 1
  }, 8, ["show", "clickOverlay", "style"]);
}