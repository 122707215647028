import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-540df8e3"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "fixed-btn"
};
var _hoisted_2 = {
  href: "https://lin.ee/RWdd0xJ",
  target: "_blank",
  rel: "noreferrer noopener"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", _hoisted_2, [_createVNode(_component_svg_icon, {
    iconName: "customerservice",
    class: "contact-img"
  })])]);
}