import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0cbf8f45"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "hotel"
};
var _hoisted_2 = {
  class: "head"
};
var _hoisted_3 = {
  class: "left"
};
var _hoisted_4 = {
  class: "title"
};
var _hoisted_5 = {
  class: "location"
};
var _hoisted_6 = {
  class: "text"
};
var _hoisted_7 = {
  key: 0,
  class: "right"
};
var _hoisted_8 = {
  class: "rating"
};
var _hoisted_9 = {
  class: "content-warp"
};
var _hoisted_10 = {
  class: "wrap"
};
var _hoisted_11 = {
  class: "center button-frame"
};
var _hoisted_12 = {
  class: "comment-frame"
};
var _hoisted_13 = {
  class: "sidebar-map"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_contry_travel_popup = _resolveComponent("contry-travel-popup");

  var _component_photos = _resolveComponent("photos");

  var _component_popup_hotel_details = _resolveComponent("popup-hotel-details");

  var _component_info = _resolveComponent("info");

  var _component_highlight = _resolveComponent("highlight");

  var _component_room_types = _resolveComponent("room-types");

  var _component_service = _resolveComponent("service");

  var _component_notice = _resolveComponent("notice");

  var _component_comment = _resolveComponent("comment");

  var _component_comment_popup = _resolveComponent("comment-popup");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_hotels_map_popup = _resolveComponent("hotels-map-popup");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_contry_travel_popup, {
    isDescriptionOpen: _ctx.isDescriptionOpen,
    onOnCloseDescription: _ctx.onCloseDescription,
    onOnOpenDescription: _ctx.onOpenDescription
  }, null, 8, ["isDescriptionOpen", "onOnCloseDescription", "onOnOpenDescription"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("section", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.hotelDetail.name), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.hotelDetail.address) + " - ", 1), _createElementVNode("div", {
    class: "map",
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.checkMapByHotelId && _ctx.checkMapByHotelId.apply(_ctx, arguments);
    })
  }, _toDisplayString(_ctx.t('search.view_map')), 1)])]), _ctx.hotelDetail.reviews ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.hotelDetail.ranking), 1)])) : _createCommentVNode("", true)]), _createVNode(_component_photos, {
    hotel: _ctx.hotelDetail
  }, null, 8, ["hotel"]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_popup_hotel_details, {
    isShow: _ctx.showSideMenu,
    hotel: _ctx.hotelDetail
  }, null, 8, ["isShow", "hotel"]), _createVNode(_component_info, {
    hotel: _ctx.hotelDetail
  }, null, 8, ["hotel"]), _createVNode(_component_highlight, {
    hotel: _ctx.hotelDetail
  }, null, 8, ["hotel"]), _createVNode(_component_room_types, {
    hotel: _ctx.hotelDetail
  }, null, 8, ["hotel"]), _createVNode(_component_service, {
    hotel: _ctx.hotelDetail
  }, null, 8, ["hotel"]), _createVNode(_component_notice, {
    hotel: _ctx.hotelDetail
  }, null, 8, ["hotel"]), _createVNode(_component_comment, {
    hotel: _ctx.hotelDetail
  }, null, 8, ["hotel"]), _withDirectives(_createElementVNode("div", _hoisted_11, [_createElementVNode("button", {
    class: "hotel-btn details",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.handleCommentPopup();
    })
  }, _toDisplayString(_ctx.t('detail.check_all_comments')), 1)], 512), [[_vShow, _ctx.showCommentBtn]]), _createVNode(_component_comment_popup, {
    handleOpen: _ctx.handleCommentPopup,
    open: _ctx.openCommentPopup,
    title: _ctx.t('detail.guest_comments')
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_12, [_createVNode(_component_comment, {
        hotel: _ctx.hotelDetail,
        isPopup: true
      }, null, 8, ["hotel"])])];
    }),
    _: 1
  }, 8, ["handleOpen", "open", "title"])]), _withDirectives(_createElementVNode("div", _hoisted_13, [_createVNode(_component_svg_icon, {
    class: "map-pin",
    iconName: "map_pin"
  }), _createElementVNode("button", {
    class: "map-btn",
    onClick: _cache[2] || (_cache[2] = //@ts-ignore
    function () {
      return _ctx.checkMapByHotelId && _ctx.checkMapByHotelId.apply(_ctx, arguments);
    })
  }, _toDisplayString(_ctx.t('detail.view_map')), 1)], 512), [[_vShow, !_ctx.isMobile]])])]), _createVNode(_component_hotels_map_popup, {
    open: _ctx.isMapOpen,
    handleOpenMap: _ctx.handleOpenMap
  }, null, 8, ["open", "handleOpenMap"])], 64);
}