import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-826a8bae"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src"];
var _hoisted_2 = {
  key: 1,
  ref: "gallery",
  class: "vgs__gallery"
};
var _hoisted_3 = {
  key: 0,
  class: "vgs__gallery__title"
};
var _hoisted_4 = ["src", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  return _openBlock(), _createBlock(_Transition, {
    name: "modal",
    class: "slider-show"
  }, {
    default: _withCtx(function () {
      return [_ctx.imgIndex !== null ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "vgs",
        onClick: _cache[4] || (_cache[4] = //@ts-ignore
        function () {
          return _ctx.close && _ctx.close.apply(_ctx, arguments);
        })
      }, [_ctx.images ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "vgs__container",
        onClick: _cache[1] || (_cache[1] = _withModifiers( //@ts-ignore
        function () {
          return _ctx.onNext && _ctx.onNext.apply(_ctx, arguments);
        }, ["stop"]))
      }, [_createElementVNode("img", {
        class: "vgs__container__img",
        src: _ctx.imageUrl,
        onClick: _cache[0] || (_cache[0] = _withModifiers( //@ts-ignore
        function () {
          return _ctx.onNext && _ctx.onNext.apply(_ctx, arguments);
        }, ["stop"]))
      }, null, 8, _hoisted_1), _renderSlot(_ctx.$slots, "default", {}, undefined, true)])) : _createCommentVNode("", true), _createElementVNode("div", null, [_ctx.isMultiple ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        type: "button",
        class: "vgs__prev",
        onClick: _cache[2] || (_cache[2] = _withModifiers( //@ts-ignore
        function () {
          return _ctx.onPrev && _ctx.onPrev.apply(_ctx, arguments);
        }, ["stop"]))
      }, [_createVNode(_component_svg_icon, {
        class: "icon",
        iconName: "popup-arrow-left"
      })])) : _createCommentVNode("", true), _ctx.isMultiple ? (_openBlock(), _createElementBlock("button", {
        key: 1,
        type: "button",
        class: "vgs__next",
        onClick: _cache[3] || (_cache[3] = _withModifiers( //@ts-ignore
        function () {
          return _ctx.onNext && _ctx.onNext.apply(_ctx, arguments);
        }, ["stop"]))
      }, [_createVNode(_component_svg_icon, {
        class: "icon",
        iconName: "popup-arrow-right"
      })])) : _createCommentVNode("", true)]), _ctx.isMultiple ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_ctx.images ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.imgIndex + 1) + " / " + _toDisplayString(_ctx.images.length), 1)) : _createCommentVNode("", true), _ctx.images ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "vgs__gallery__container",
        style: _normalizeStyle({
          transform: 'translate(' + _ctx.galleryXPos + 'px, 0)'
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, function (img, i) {
        return _openBlock(), _createElementBlock("img", {
          key: i,
          class: _normalizeClass(["vgs__gallery__container__img", {
            'vgs__gallery__container__img--active': i === _ctx.imgIndex
          }]),
          src: img,
          onClick: _withModifiers(function ($event) {
            return _ctx.onClickThumb(img, i);
          }, ["stop"])
        }, null, 10, _hoisted_4);
      }), 128))], 4)) : _createCommentVNode("", true)], 512)) : _createCommentVNode("", true)])) : _createCommentVNode("", true)];
    }),
    _: 3
  });
}