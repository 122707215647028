import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2c61ad03"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "info"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  key: 0,
  id: "service",
  class: "info-content mobile"
};
var _hoisted_4 = {
  class: "sub-title"
};
var _hoisted_5 = {
  class: "container service"
};
var _hoisted_6 = {
  class: "text"
};
var _hoisted_7 = {
  id: "service",
  class: "info-content"
};
var _hoisted_8 = {
  class: "sub-title"
};
var _hoisted_9 = {
  class: "container service"
};
var _hoisted_10 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_readmore = _resolveComponent("readmore");

  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t('detail.facilities_and_services')), 1), _ctx.isMobile ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotel.facilities, function (service, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [index === 0 ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createElementVNode("h3", _hoisted_4, _toDisplayString(service.name), 1), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(service.facilities.slice(0, 6), function (data, index) {
      return _openBlock(), _createElementBlock("div", {
        class: "item",
        key: index
      }, [_createElementVNode("div", {
        class: _normalizeClass('myicon ' + data.class)
      }, null, 2), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t(data.lang_key)), 1)]);
    }), 128))])], 64)) : _createCommentVNode("", true)]);
  }), 128))])) : (_openBlock(), _createBlock(_component_readmore, {
    key: 1,
    type: "slot",
    target: "service"
  }, {
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotel.facilities, function (service, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("h3", _hoisted_8, _toDisplayString(service.name), 1), _createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(service.facilities, function (data, index) {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: index
          }, [_createElementVNode("div", {
            class: _normalizeClass('myicon ' + data.class)
          }, null, 2), _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t(data.lang_key)), 1)]);
        }), 128))])]);
      }), 128))])];
    }),
    _: 1
  })), _createElementVNode("button", {
    class: "hotel-btn details mobile-only",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.toggleHotel();
    })
  }, _toDisplayString(_ctx.t('detail.more_info_on_the_hotel')), 1)]);
}