import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3a4c039f"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "info"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "container"
};
var _hoisted_4 = {
  class: "wording"
};
var _hoisted_5 = {
  class: "type"
};
var _hoisted_6 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t('detail.highlights_of_lodging')), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.highlights, function (data, index) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["highlights", {
        visible: !data.text
      }]),
      key: index
    }, [_createVNode(_component_svg_icon, {
      class: "icon",
      iconName: data.icon
    }, null, 8, ["iconName"]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t(data.type)), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(data.text ? _ctx.t(data.text) : ''), 1)])], 2);
  }), 128))])]);
}